/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.directive('userInteractionsResidentDetails', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/userInteractions/user-interactions-resident-details.html',
      controller: 'UserInteractionsResidentDetailsController',
      scope: {
        resident: '=',
        stream: '='
      }
    };
  });

  const UserInteractionsResidentDetailsController = function (
    $rootScope,
    $scope,
    $q,
    $location,
    $routeParams,
    appDataService,
    historyService,
    conversationsService,
    residentService,
    relayApi,
    $moment,
    $mdDialog,
    $mdToast,
    _,
    profileService
  ) {
    var self = this;

    var smsConsentChangedDestroy = $rootScope.$on(
      conversationsService.events.smsConsentChanged,
      function (event, payload) {
        if (
          payload.type === 'resident' &&
          $scope.resident &&
          payload.id === $scope.resident.id
        ) {
          if (!$scope.resident.sms_consent) {
            $scope.resident.sms_consent = {};
          }

          $scope.resident.sms_consent.status = payload.status;
        }
      }
    );

    // Handle carousel swap:
    $scope.$watch('resident.id', function () {
      self._initialize();
    });

    $scope.$on('$destroy', function () {
      smsConsentChangedDestroy();
    });

    $rootScope.$on('residentPackagesAdded', function (event, residentPackages) {
      // I had trouble getting the resident reload to propagate, so I chose instead to pass and add the packages
      // directly to the resident events.

      _.forEach(residentPackages, function (residentPackage) {
        residentPackage.event_type = 'package';
      });

      $scope.resident.events = $scope.resident.events.concat(residentPackages);
    });

    $scope.data = {
      isEditing: false,
      isLoaded: false,
      isDeletingResident: false,
      isSavingResident: false,
      residentSaved: false,
      residentSaveError: false,
      properties: {
        community: [],
        singleFamily: []
      },
      selectedProperty: null,
      statusLabels: null,
      showResidentDeeplink: false
    };

    $scope.profile = $scope.resident.profile;

    $scope.deleteResident = function () {
      $scope.data.isDeletingResident = true;

      residentService
        .deleteResident($scope.resident)
        .success(self._deleteResidentSuccess);
    };

    self._deleteResidentSuccess = function () {
      $scope.data.isDeletingResident = false;

      if ($routeParams.chatThreadId === $scope.resident.stream_id) {
        $location.url(
          $rootScope.featureFlags.RESIDENT_PAGE_REDESIGN ||
            localStorage.getItem('ProspectResidentBeta') === 'true'
            ? '/residents'
            : '/reports/residents'
        );
      } else {
        $mdDialog.cancel();
      }
    };

    $scope.editResident = function () {
      $scope.data.uneditedResident = angular.copy($scope.resident);
      $scope.data.isEditing = true;
    };

    $scope.cancelEdit = function () {
      $scope.resident = $scope.data.uneditedResident;
      $scope.data.isEditing = false;
    };

    $scope.saveResident = function () {
      if ($scope.resident.lease_start) {
        $scope.resident.lease_start = $moment(
          $scope.resident.lease_start
        ).format();
      }

      if ($scope.resident.lease_end) {
        $scope.resident.lease_end = $moment($scope.resident.lease_end).format();
      }

      self._setResidentResidence();

      $scope.data.isSavingResident = true;
      $scope.data.residentSaved = false;
      $scope.data.residentSaveError = false;

      residentService
        .updateResident($scope.resident)
        .error(self._saveResidentError)
        .success(self._saveResidentFinished);
    };

    $scope.reassignStreamPhoneNumber = function () {
      self._postReassignStreamPhoneNumber();
    };

    $scope.refreshAvailableStatuses = function () {
      $scope.data.statusLabels = angular.copy(residentService.statusLabels);
      // delete $scope.data.statusLabels['former'];

      if (!residentService.withinThreshold($scope.resident)) {
        delete $scope.data.statusLabels['on-notice'];
        delete $scope.data.statusLabels['renewing'];

        var oldStatus = $scope.resident.status;

        if (oldStatus === 'on-notice' || oldStatus === 'renewing') {
          // Force status to be Current. This happens when the status was
          // Renewing or On Notice, and the lease end was updated to something new.
          $scope.resident.status = 'current';
          var oldStatusLabel = residentService.statusLabels[oldStatus];
          $mdToast.showSimple(
            'Stage changed from ' +
              oldStatusLabel +
              ' to Current to reflect new lease end date'
          );
        }
      } else {
        // Update the current status label
        $scope.data.statusLabels.current = 'Undecided';

        let undecidedKey = 'undecided';
        if ($scope.data.statusLabels[undecidedKey]) {
          // Remove the duplicate or conflicting label
          delete $scope.data.statusLabels[undecidedKey];
        }
      }
    };

    $scope.smsConsentStatus = function () {
      if (!$scope.resident.sms_consent) {
        return 'No';
      } else if ($scope.resident.sms_consent.status === 'granted') {
        return 'Yes';
      } else if ($scope.resident.sms_consent.status === 'revoked') {
        return 'Stopped';
      } else if ($scope.resident.sms_consent.status === 'declined') {
        return 'Declined';
      } else {
        return 'No';
      }
    };

    $scope.sortEvents = function (event) {
      var sortable =
        event.event_type === 'delivery'
          ? event.received_at
          : event.created_time;

      return $moment(sortable).unix();
    };

    self._setResidentResidence = function () {
      $scope.resident.residence = {
        unit: $scope.resident.residence.unit,
        property_id: $scope.data.selectedProperty.id
      };
    };

    self._saveResidentError = function () {
      $scope.data.isSavingResident = false;
      $scope.data.residentSaveError = true;
    };
    $scope.formatLeaseDates = function () {
      if ($scope.resident.lease_start) {
        $scope.resident.lease_start = $scope.resident.lease_start.replace(
          '-',
          '/'
        );
      }
      if ($scope.resident.lease_end) {
        $scope.resident.lease_end = $scope.resident.lease_end.replace('-', '/');
      }
    };
    self._saveResidentFinished = function () {
      $scope.formatLeaseDates();
      $scope.data.isSavingResident = false;
      $scope.data.residentSaved = true;
      $scope.data.isEditing = false;
    };

    self._initialize = function () {
      $scope.formatLeaseDates();
      $scope.refreshAvailableStatuses();

      $scope.isPmsResident = _.includes(
        ['amsi', 'entrata', 'mri', 'realpage', 'resman', 'yardi', 'appfolio'],
        $scope.resident.source
      );

      $scope.isResidentEnable =
        $rootScope.appPreferences.company.allowed_property_type === 'mf';

      $scope.pmsStatusChange = _.includes(
        ['realpage', 'yardi', 'entrata', 'amsi', 'resman'],
        $scope.resident.source
      );

      $scope.is_multi_family_residents_enabled =
        $rootScope.appPreferences.company.multi_family_residents_enabled;

      self._setTeamMembers();
      self._setProperties();
      self._loadFinished();
      self._setShowResidentDeeplink();
    };

    self._setTeamMembers = function () {
      $scope.data.teamMembers = appDataService.getTeamMembers();
    };

    self._setShowResidentDeeplink = function () {
      const isOneSiteResidentCardLinkEnabled =
        $rootScope.featureFlags.ONESITE_RESIDENT_CARD_LINK;

      if (isOneSiteResidentCardLinkEnabled && $scope.resident.vendor_id) {
        profileService.getProfile(true).success(function (response) {
          const profile = response.profile;
          if (profile.is_migrated) {
            $scope.data.showResidentDeeplink = true;
            return;
          }
        });
      }
    };

    self._setProperties = function () {
      var properties = appDataService.getProperties();

      $scope.data.properties.multiFamily = _.filter(
        properties,
        function (property) {
          return property.Property.type === 'multi-family';
        }
      );

      $scope.data.properties.singleFamily = _.filter(
        properties,
        function (property) {
          return property.Property.type === 'single-family';
        }
      );

      var selectedPropertyResult = _.find(
        properties,
        function (propertyResult) {
          return (
            propertyResult.Property.id === $scope.resident.residence.property_id
          );
        }
      );

      if (selectedPropertyResult) {
        $scope.data.selectedProperty = selectedPropertyResult.Property;
      }
    };

    self._postReassignStreamPhoneNumber = function () {
      return relayApi
        .reassignStreamPhoneNumber($scope.resident.stream_id)
        .then((response) => {
          if (response && response.data.lead.assigned_relay_phone) {
            $mdToast.showSimple('Assigned relay phone!');
            $scope.resident.assigned_relay_phone =
              response.data.lead.assigned_relay_phone;
          } else $mdToast.showSimple('No unique relay phone number available');
        })
        .catch(() => {
          $mdToast.showSimple('Could not assign relay phone');
        });
    };

    self._loadFinished = function () {
      $scope.data.isLoaded = true;
    };

    self._initialize();
  };

  UserInteractionsResidentDetailsController.$inject = [
    '$rootScope',
    '$scope',
    '$q',
    '$location',
    '$routeParams',
    'appDataService',
    'historyService',
    'conversationsService',
    'residentService',
    'relayApi',
    '$moment',
    '$mdDialog',
    '$mdToast',
    '_',
    'ProfileService'
  ];

  app.controller(
    'UserInteractionsResidentDetailsController',
    UserInteractionsResidentDetailsController
  );
})(window.angular);
