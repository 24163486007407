import React from 'react';

export const ApplicantsPathIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.125 3.875H9.8875C9.99984 3.8761 10.1096 3.90865 10.2044 3.96897C10.2992 4.02929 10.3752 4.11495 10.4237 4.21625C10.6045 4.5984 10.8895 4.92168 11.2461 5.14882C11.6026 5.37595 12.016 5.49769 12.4388 5.5H17.6875C17.903 5.5 18.1097 5.4144 18.262 5.26202C18.4144 5.10965 18.5 4.90299 18.5 4.6875C18.5 4.47201 18.4144 4.26535 18.262 4.11298C18.1097 3.9606 17.903 3.875 17.6875 3.875H12.4388C12.3264 3.8739 12.2166 3.84135 12.1218 3.78103C12.0271 3.72071 11.9511 3.63505 11.9025 3.53375C11.7218 3.1516 11.4367 2.82832 11.0802 2.60118C10.7237 2.37405 10.3102 2.25231 9.8875 2.25H7.125C6.90951 2.25 6.70285 2.3356 6.55048 2.48798C6.3981 2.64035 6.3125 2.84701 6.3125 3.0625C6.3125 3.27799 6.3981 3.48465 6.55048 3.63702C6.70285 3.7894 6.90951 3.875 7.125 3.875Z"
        fill="white"
      />
      <path
        d="M17.2812 15.25C16.7425 15.25 16.2259 15.464 15.8449 15.8449C15.464 16.2259 15.25 16.7425 15.25 17.2812C15.25 17.389 15.2072 17.4923 15.131 17.5685C15.0548 17.6447 14.9515 17.6875 14.8438 17.6875H9.15625C9.04851 17.6875 8.94517 17.6447 8.86899 17.5685C8.7928 17.4923 8.75 17.389 8.75 17.2812C8.75 16.7425 8.53599 16.2259 8.15506 15.8449C7.77413 15.464 7.25747 15.25 6.71875 15.25H2.65625C2.54851 15.25 2.44517 15.2928 2.36899 15.369C2.2928 15.4452 2.25 15.5485 2.25 15.6562V21.3438C2.25 21.4515 2.2928 21.5548 2.36899 21.631C2.44517 21.7072 2.54851 21.75 2.65625 21.75H21.3438C21.4515 21.75 21.5548 21.7072 21.631 21.631C21.7072 21.5548 21.75 21.4515 21.75 21.3438V15.6562C21.75 15.5485 21.7072 15.4452 21.631 15.369C21.5548 15.2928 21.4515 15.25 21.3438 15.25H17.2812Z"
        fill="white"
      />
      <path
        d="M5.5 14.0312C5.60774 14.0312 5.71108 13.9884 5.78726 13.9123C5.86345 13.8361 5.90625 13.7327 5.90625 13.625V6.92188C5.90625 6.81413 5.94905 6.7108 6.02524 6.63461C6.10142 6.55843 6.20476 6.51562 6.3125 6.51562H8.66875C8.78109 6.51673 8.89087 6.54928 8.98566 6.60959C9.08044 6.66991 9.15642 6.75557 9.205 6.85687C9.3833 7.23411 9.66332 7.55411 10.0135 7.7809C10.3638 8.00769 10.7703 8.13227 11.1875 8.14062H17.6875C17.7952 8.14062 17.8986 8.18343 17.9748 8.25961C18.0509 8.3358 18.0938 8.43913 18.0938 8.54688V13.625C18.0938 13.7327 18.1366 13.8361 18.2127 13.9123C18.2889 13.9884 18.3923 14.0312 18.5 14.0312H19.3125C19.3726 14.0303 19.4318 14.0169 19.4864 13.9917C19.541 13.9665 19.5897 13.9301 19.6294 13.885C19.6661 13.837 19.6923 13.7818 19.7063 13.723C19.7203 13.6642 19.7218 13.6031 19.7106 13.5437V7.32812C19.7106 7.11264 19.625 6.90597 19.4727 6.7536C19.3203 6.60123 19.1136 6.51562 18.8981 6.51562H11.1875C11.0752 6.51452 10.9654 6.48197 10.8706 6.42166C10.7758 6.36134 10.6998 6.27568 10.6512 6.17438C10.4729 5.79714 10.1929 5.47714 9.8427 5.25035C9.49247 5.02356 9.08591 4.89898 8.66875 4.89062H5.09375C4.87826 4.89062 4.6716 4.97623 4.51923 5.1286C4.36685 5.28097 4.28125 5.48764 4.28125 5.70312V13.5437C4.27009 13.6031 4.27155 13.6642 4.28555 13.723C4.29955 13.7818 4.32577 13.837 4.3625 13.885C4.40309 13.9312 4.4531 13.9681 4.50916 13.9933C4.56522 14.0186 4.62603 14.0315 4.6875 14.0312H5.5Z"
        fill="white"
      />
      <path
        d="M12.9669 12.8856V12.8206C13.3676 12.3332 13.5681 11.7116 13.5275 11.0819C13.5275 10.8845 13.4886 10.689 13.4131 10.5067C13.3375 10.3243 13.2268 10.1586 13.0872 10.019C12.9477 9.87943 12.782 9.76871 12.5996 9.69317C12.4172 9.61763 12.2218 9.57875 12.0244 9.57875C11.827 9.57875 11.6315 9.61763 11.4492 9.69317C11.2668 9.76871 11.1011 9.87943 10.9615 10.019C10.8219 10.1586 10.7112 10.3243 10.6357 10.5067C10.5601 10.689 10.5212 10.8845 10.5212 11.0819C10.479 11.6877 10.6645 12.2875 11.0413 12.7638C11.0463 12.8042 11.0463 12.8452 11.0413 12.8856C10.1312 13.2269 9.6275 13.4869 9.41625 13.9256C9.29764 14.1832 9.21822 14.4571 9.18062 14.7381C9.16993 14.7966 9.17218 14.8567 9.18723 14.9141C9.20228 14.9716 9.22975 15.0251 9.26772 15.0708C9.30568 15.1165 9.3532 15.1533 9.40695 15.1787C9.46069 15.204 9.51933 15.2173 9.57875 15.2175H14.4538C14.5132 15.2173 14.5718 15.204 14.6256 15.1787C14.6793 15.1533 14.7268 15.1165 14.7648 15.0708C14.8027 15.0251 14.8302 14.9716 14.8453 14.9141C14.8603 14.8567 14.8626 14.7966 14.8519 14.7381C14.8143 14.4571 14.7349 14.1832 14.6163 13.9256C14.3806 13.4869 13.8769 13.2269 12.9669 12.8856Z"
        fill="white"
      />
    </svg>
  );
};
