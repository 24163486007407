/* Components */
import { react2angular } from 'react2angular';

import { BatchSelectCheckbox } from '../components/BatchSelectCheckbox';
import { ConnectedProfilesDialog } from '../components/ConnectedProfiles';
import { CreateQuote } from '../components/CreateQuote';
import DuplicateAlert from '../components/DuplicateAlert';
import { GlobalNavHeader } from '../components/GlobalNavHeader';
import { GlobalNavHeaderNew } from '../components/GlobalNavHeaderNew';
import MergeModal from '../components/MergeModal';
import { NearbyCommunitiesContainer } from '../components/NearbyCommunities';
import RichTextEditor from '../components/RichTextEditor';
import { TablePaginationControls } from '../components/TablePaginationControls';
import Unsubscribe from '../components/Unsubscribe';
import ViewConnectedProfiles from '../components/ViewConnectedProfiles';
import ViewProspectInOnSite from '../components/ViewProspectInOnSite';
import ViewProspectInOneSite from '../components/ViewProspectInOneSite';
import ViewResidentInOneSite from '../components/ViewResidentInOneSite';
import { EmptyTodoListMessage } from '../components/EmptyTodoListMessage';

import {
  AttachmentIcon,
  PersonSecureIcon,
  PhoneTextIcon,
  ReceiptIcon,
  MessageBubbleDialogueTextIcon
} from '../components/icons';
import { CharacterCountTextField } from '../components/CharacterCountTextField';
import { QuickReplyHost } from '../components/QuickReplies';
import { SelfVerifyIdButton } from '../components/ConversationActions/SelfVerifyIdButton';
import { SMSOptInButton } from '../components/ConversationActions/SMSOptInButton';
import { ApplicationButton } from '../components/ConversationActions/ApplicationButton';
import { AttachmentsButton } from '../components/ConversationActions/AttachmentsButton';
import { AvailabilityButton } from '../components/ConversationActions/AvailabilityButton';
import { SendButton } from '../components/ConversationActions/SendButton';
import { ConversationOverflowMenu } from '../components/ConversationOverflowMenu/ConversationOverflowMenu';
import { ConversationEmail } from '../components/ConversationEmail/ConversationEmail';
import { LeasingBinderDrawer } from '../components/LeasingBinder/LeasingBinderDrawer';
import NoteModal from '../components/NoteModal';
import { QuoteSummary } from '../components/CreateQuote/QuoteSummary';
import { LoadingQuoteToast } from '../components/CreateQuote/LoadingQuoteToast';
import ProspectsPage from '../components/Prospects/ProspectsPage';
import ResidentsPage from '../components/Residents/ResidentsPage';
import ShareNewListingModal from '../components/ShareListingModal/ShareListingModal';
import HotLeadIndicator from '../components/GuestCard/HotLeadIndicator';
import { FollowUpModalGuestCard } from '../components/Prospects/ProspectModals/FollowUpModal';
import BatchEmailIndicator from '../components/Conversations/Messages/BatchEmailIndicator';
import ProspectList from '../components/Dashboard/Todo/ProspectList';
import ResidentList from '../components/Dashboard/Todo/ResidentList';

import WalkinModal from '../components/WalkinModal';
import MarkAsLostDrawer from '../components/Drawer/MarkAsLostDrawer/MarkAsLostDrawer';
import BulkEmail from '../components/Modals/BulkEmail';
import AvailabilityDrawer from '../components/Drawer/Availability/AvailabilityDrawer';
import { LeasingBrochure } from '../components/LeasingBrochure/LeasingBrochure';
import { CallSettings } from '../components/Users/Profile';
import { LeftNavigatorDrawer } from '../components/Nav/LeftNavigator';
import ColorPickerModal from '../components/Users/Profile/ColorPickerModal';
import Dashboard from '../components/Dashboard/Dashboard';
import CompleteTourDrawer from '../components/Drawer/CompleteTourDrawer/CompleteTourDrawer';
import InitialsAvatar from '../components/Users/Profile/InitialsAvatar';

export const loadReactComponents = (app: any) => {
  app.component(
    'batchSelectCheckbox',
    react2angular(BatchSelectCheckbox, [
      'isIndeterminate',
      'isChecked',
      'onChangeBatchOption'
    ])
  );

  app.component(
    'noteModal',
    react2angular(NoteModal, ['open', 'closeModal', 'addActivity'])
  );

  app.component(
    'connectedProfilesDialog',
    react2angular(ConnectedProfilesDialog, [
      'connectedProfiles',
      'firstName',
      'isActive',
      'lastName',
      'setIsActive'
    ])
  );

  app.component(
    'shareNewListingModal',
    react2angular(ShareNewListingModal, [
      'pasteUnitListingLink',
      'appProperties',
      'getPropertyUnitListings',
      'generateQuote',
      'quoteEnable'
    ])
  );

  app.component(
    'createQuote',
    react2angular(CreateQuote, [
      'attachQuoteErrorMessage',
      'attachQuoteToMessage',
      'blackoutDatesMap',
      'cancelCreateQuote',
      'createQuote',
      'createQuoteErrorMessage',
      'fetchQuoteConcessions',
      'handleGenerateQuote',
      'hasValidRentMatrix',
      'isCreateQuoteDialogOpen',
      'isRealPageIntegration',
      'lease',
      'quote',
      'quoteConcessions',
      'unit',
      'upchargeDetails'
    ])
  );

  app.component(
    'quoteSummary',
    react2angular(QuoteSummary, [
      'attachQuoteErrorMessage',
      'attachQuoteToMessage',
      'cancelUnifiedQuote',
      'isCreateQuoteDialogOpen',
      'quote',
      'unit'
    ])
  );

  app.component('loadingQuoteToast', react2angular(LoadingQuoteToast));

  app.component(
    'duplicateAlert',
    react2angular(DuplicateAlert, [
      'toggleModal',
      'mergeOptions',
      'toggleDuplicateAlert',
      'showDuplicateAlert'
    ])
  );

  app.component(
    'globalNavHeader',
    react2angular(GlobalNavHeader, [
      'currentLeasingTeam',
      'headerTitle',
      'headerSubTitle',
      'leasingTeams',
      'userProfile',
      'userLogout',
      'openKnockAnalytics',
      'openClawApp',
      'isClawAvailable',
      'openProfileAccount',
      'hideAnalytics',
      'hideAccountViews',
      'betaSwitch',
      'isRedesignBeta'
    ])
  );

  app.component(
    'globalNavHeaderNew',
    react2angular(GlobalNavHeaderNew, [
      'currentLeasingTeam',
      'headerTitle',
      'headerSubTitle',
      'leasingTeams',
      'userProfile',
      'userLogout',
      'openKnockAnalytics',
      'openClawApp',
      'isClawAvailable',
      'openProfileAccount',
      'hideAnalytics',
      'hideAccountViews',
      'betaSwitch',
      'isRedesignBeta',
      'openKnockVoice',
      'openAdminApp',
      'openSideNavDrawer',
      'openProfileSettings',
      'browserAudioNotificationsToggle',
      'audioNotificationsEnabled',
      'browserNotificationsToggle',
      'desktopNotificationsEnabled',
      'desktopNotificationsUnavailable',
      'hideChangeUser',
      'memberLogin',
      'hideSettings',
      'inboundBrowserCallEnable',
      'isVoiceAppStatusFeatureEnable',
      'voiceAppStatus',
      'focusOnKnockVoice'
    ])
  );

  app.component(
    'mergeModal',
    react2angular(MergeModal, [
      'toggleModal',
      'showMergeModal',
      'mergeOptions',
      'proposedMaster',
      'getMergeDecisions',
      'setMergeDecisions',
      'onSuccess'
    ])
  );

  app.component(
    'nearbyCommunities',
    react2angular(NearbyCommunitiesContainer, [
      'propertyId',
      'prospectId',
      'preferences',
      'shouldShowConnectedProfiles',
      'isActive',
      'onReferral',
      'setIsConnectedProfilesDialogActive'
    ])
  );

  app.component(
    'richTextEditor',
    react2angular(RichTextEditor, ['onChange', 'content', 'token', 'mergeTags'])
  );

  app.component(
    'tablePaginationControls',
    react2angular(TablePaginationControls, [
      'getRangeBounds',
      'pageNumber',
      'onChangeResultsPageNavigation',
      'onChangeRowsPerPage',
      'rowsPerPage',
      'totalRows',
      'isPaginationEnabled'
    ])
  );

  app.component('unsubscribeComponent', react2angular(Unsubscribe, []));

  app.component(
    'viewConnectedProfiles',
    react2angular(ViewConnectedProfiles, [
      'connectedProfilesCount',
      'isCrossSellEnabled',
      'viewProfiles'
    ])
  );
  app.component('hotLeadIndicator', react2angular(HotLeadIndicator, []));

  app.component(
    'viewProspectInOnSite',
    react2angular(ViewProspectInOnSite, ['prospect'])
  );

  app.component(
    'viewProspectInOneSite',
    react2angular(ViewProspectInOneSite, ['prospectId'])
  );

  app.component(
    'emptyTodoListMessage',
    react2angular(EmptyTodoListMessage, [
      'managerId',
      'managerUnreadMessageTotal',
      'openManagerInbox'
    ])
  );

  app.component(
    'attachmentIcon',
    react2angular(AttachmentIcon, [
      'className',
      'height',
      'strokeColor',
      'width',
      'useNewDesign'
    ])
  );

  app.component(
    'messageBubbleDialogueTextIcon',
    react2angular(MessageBubbleDialogueTextIcon, [
      'className',
      'height',
      'strokeColor',
      'width'
    ])
  );

  app.component(
    'personSecureIcon',
    react2angular(PersonSecureIcon, [
      'className',
      'height',
      'strokeColor',
      'width',
      'useNewDesign'
    ])
  );

  app.component(
    'phoneTextIcon',
    react2angular(PhoneTextIcon, [
      'className',
      'height',
      'strokeColor',
      'width'
    ])
  );

  app.component(
    'receiptIcon',
    react2angular(ReceiptIcon, ['className', 'height', 'strokeColor', 'width'])
  );

  app.component(
    'selfVerifyIdButton',
    react2angular(SelfVerifyIdButton, [
      'disable',
      'onClick',
      'isEmailAvailable',
      'isPlaidIdvEnabled'
    ])
  );

  app.component(
    'smsOptInButton',
    react2angular(SMSOptInButton, ['disable', 'onClick'])
  );

  app.component(
    'applicationButton',
    react2angular(ApplicationButton, ['onClick'])
  );

  app.component(
    'attachmentsButton',
    react2angular(AttachmentsButton, ['onClick'])
  );

  app.component('availabilityButton', react2angular(AvailabilityButton, []));

  app.component(
    'sendButton',
    react2angular(SendButton, ['disable', 'onClick'])
  );

  app.component(
    'characterCountTextField',
    react2angular(CharacterCountTextField, [
      'imageIndex',
      'label',
      'maxLength',
      'onChange',
      'placeholder',
      'showCharCount',
      'value',
      'variant'
    ])
  );

  app.component(
    'quickReplyHost',
    react2angular(QuickReplyHost, [
      'messageMode',
      'managerInfo',
      'getManagerQuickReplies',
      'saveQuickReply',
      'deleteQuickReply',
      'onAppendText',
      'mergeTags',
      'isCompanyQuickRepliesEnabled'
    ])
  );

  app.component(
    'conversationOverflowMenu',
    react2angular(ConversationOverflowMenu, ['menuItems', 'onMenuItemClick'])
  );

  app.component(
    'conversationEmail',
    react2angular(ConversationEmail, [
      'toName',
      'toEmailAddress',
      'ownerInfo',
      'defaultSubject',
      'defaultRecipients',
      'emailDomainBlocklist',
      'onChangeSubject',
      'onChangeRecipients',
      'prospectId',
      'isBulkCommunicationEnhancementEnabled',
      'loggedinUser',
      'setSendAsUserId',
      'isAgentAttributionEnable'
    ])
  );

  /* Enable while converting phoneformatter to react */
  // app.component(
  //   'phoneInput',
  //   react2angular(PhoneInput, ['phone', 'name', 'phoneChange'])
  // );

  app.component(
    'leasingBinderDrawer',
    react2angular(LeasingBinderDrawer, [
      'open',
      'getProperties',
      'managerCommunityIds',
      'onClose',
      'currentProperty',
      'prospectStatus',
      'bookTourAction',
      'availableUnitsAction',
      'markAsLostCall',
      'defaultPropertyId',
      'isNewAvailabilityDrawerEnable',
      'attachBrochure',
      'isEmailAvailable'
    ])
  );

  app.component(
    'prospectsPage',
    react2angular(ProspectsPage, [
      'properties',
      'managers',
      'sources',
      'token',
      'managerInfo',
      'mergeTags',
      'getManagerQuickReplies',
      'saveQuickReply',
      'deleteQuickReply',
      'isCompanyQuickRepliesEnabled',
      'referralsEnabled',
      'openProspectStreamModal',
      'handleAttachments',
      'deleteAttachment',
      'sendBatchMessage',
      'openCarousel',
      'cheatProofEngagementScoreEnabled',
      'timeService',
      'removeQueryStringParam',
      'currentCompanyId',
      'isDemandXEnabled',
      'isDemandXPriorityEnabled',
      'isDemandXDemoEnabled',
      'isInternal',
      'emailDomainBlocklist',
      'isBulkCommunicationEnhancementEnabled',
      'isAgentAttributionEnable',
      'generateTimeOptions',
      'saveWalkinDetails',
      'openShownUnits'
    ])
  );

  app.component(
    'residentPage',
    react2angular(ResidentsPage, [
      'properties',
      'managers',
      'token',
      'managerInfo',
      'mergeTags',
      'getManagerQuickReplies',
      'saveQuickReply',
      'deleteQuickReply',
      'isCompanyQuickRepliesEnabled',
      'handleAttachments',
      'deleteAttachment',
      'sendBatchMessage',
      'openCarousel',
      'allowCreateResident',
      'currentUserId',
      'validateEmail',
      'openResidentStreamModal',
      'timeService',
      'cheatProofEngagementScoreEnabled',
      'removeQueryStringParam',
      'emailDomainBlocklist',
      'isBulkCommunicationEnhancementEnabled',
      'isAgentAttributionEnable'
    ])
  );

  app.component(
    'followUpModalGuestCard',
    react2angular(FollowUpModalGuestCard, [
      'open',
      'closeModal',
      'records',
      'saveActivityForProspects',
      'timeService',
      'hideNameChips'
    ])
  );

  app.component('batchEmailIndicator', react2angular(BatchEmailIndicator), []);

  app.component(
    'prospectList',
    react2angular(ProspectList, ['prospects', 'isDemandXDemoEnabled'])
  );

  app.component('residentList', react2angular(ResidentList, ['residents']));

  app.component(
    'markAsLostDrawer',
    react2angular(MarkAsLostDrawer, [
      'closeDrawer',
      'open',
      'openLeasingBinder',
      'mergeTags',
      'prospect',
      'timeService',
      'sendEmail',
      'isReferralsEnabled',
      'getMyCommunities',
      'closeScheduleTour'
    ])
  );
  app.component(
    'walkinModal',
    react2angular(WalkinModal, [
      'generateTimeOptions',
      'requireFloorPlan',
      'getFloorPlans',
      'useNewUnitsFeature',
      'teamMembers',
      'saveWalkinDetails',
      'walkinDataSaving',
      'selectedFloorPlanId',
      'managerId',
      'closeWalkinModal'
    ])
  );

  app.component(
    'bulkEmail',
    react2angular(BulkEmail, [
      'mergeTags',
      'token',
      'managerInfo',
      'getManagerQuickReplies',
      'saveQuickReply',
      'deleteQuickReply',
      'isCompanyQuickRepliesEnabled',
      'handleAttachments',
      'deleteAttachment',
      'sendBatchMessage',
      'cheatProofEngagementScoreEnabled',
      'preSelectedResidents',
      'title',
      'subject',
      'closeModal',
      'openModal',
      'emailDomainBlocklist'
    ])
  );
  app.component(
    'availabilityDrawer',
    react2angular(AvailabilityDrawer, [
      'onClose',
      'open',
      'currentProperty',
      'getUnitsApi',
      'showAvailabilityBackButton',
      'prospect',
      'getUnitLink',
      'properties',
      'vendorProspectId',
      'isConnectedProfilesEnabled',
      'hasRealPageIntegration',
      'prospectIntegrationsApi',
      'unitQuotesService',
      'createQuote',
      'attachQuoteToMessage',
      'isSendQuoteDisabled',
      'attachBrochure',
      'closeLeasingBinder',
      'connectedProfiles'
    ])
  );

  app.component(
    'completeTourDrawer',
    react2angular(CompleteTourDrawer, [
      'openCompleteTourDrawer',
      'infoPanelData',
      'onClose'
    ])
  );

  app.component('leasingBrochure', react2angular(LeasingBrochure, []));

  app.component('dashboard', react2angular(Dashboard, []));

  app.component(
    'callSettings',
    react2angular(CallSettings, ['setCallSettings'])
  );

  app.component(
    'colorPickerModal',
    react2angular(ColorPickerModal, ['color', 'setColor'])
  );

  app.component(
    'leftNavigator',
    react2angular(LeftNavigatorDrawer, [
      'goTo',
      'todoCount',
      'currentPath',
      'inbox',
      'open',
      'currentLeasingTeam',
      'leasingTeams',
      'close',
      'hideResidents',
      'hideLeasingBinder',
      'leasingTeamId',
      'openTeamInboxSettings',
      'isInternalTrueUrl',
      'isClawApplicantsPageAvailable'
    ])
  );

  app.component(
    'initialsAvatar',
    react2angular(InitialsAvatar, ['color', 'firstName', 'lastName'])
  );

  app.component(
    'viewResidentInOneSite',
    react2angular(ViewResidentInOneSite, ['residentId'])
  );
};
