import React from 'react';
import { makeStyles } from '@knockrentals/knock-shared-web';

const BetaButton: React.FC = () => {
  const useStyles = makeStyles({
    ButtonContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      marginLeft: '5px',
      width: '40px',
      height: '20px',
      background: '#B9F3F3',
      color: '#024E4E',
      border: 'none',
      borderRadius: '9px'
    }
  });
  const classes = useStyles();
  return <button className={classes.ButtonContainer}>Beta</button>;
};

export default BetaButton;
