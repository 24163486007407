import {
  Drawer,
  NamedColors,
  Text,
  ThemeProvider,
  black,
  denim,
  makeStyles,
  white
} from '@knockrentals/knock-shared-web';
import {
  Avatar,
  Box,
  Collapse,
  hexToRgb,
  useMediaQuery,
  Link
} from '@material-ui/core';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  AvailabilityPathIcon,
  CalendarPathIcon,
  InboxIcon,
  LeasingBinderPathIcon,
  ProspectsPathIcon,
  ResidentsPathIcon,
  SearchPathIcon,
  ExternalLinkIcon
} from '../icons';
import { ApplicantsPathIcon } from '../icons/ApplicantsPathIcon';
import BetaButton from '../icons/BetaIcon';
import { colors, useCommonStyles } from '../commonStyles/commonStyles';
import { EllipseIcon, ToDoIcon } from '../icons';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import LeasingTeamSelector from '../GlobalNavHeaderNew/LeasingTeamSelector';
import useLeasingTeamBadges from 'app/services/me/leasingTeam/Badges/hooks';
import { Provider } from 'react-redux';
import { store } from '../../../../app/store';
import { Tooltip } from '@knockrentals/knock-shared-web';
import { APP_CONFIG } from '../../../../APP_CONFIG';

const CLAW_BASE_URL = APP_CONFIG.CLAW_BASE_URL;

const useStyles = makeStyles({
  drawerContainer: {
    '& .MuiDrawer-paper': {
      width: '240px',
      '& .left-nav-container': {
        top: 0,
        paddingTop: '65px'
      }
    }
  },
  navContainer: {
    backgroundColor: '#2EA683',
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 1004,
    width: '240px',
    height: '100%',
    paddingBottom: '70px',
    top: '65px'
  },
  pathContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px 16px',
    cursor: 'pointer',
    borderRight: `3px solid #2EA683`,
    height: '48px',
    '&.active': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      borderRight: `3px solid #088D61`
    },
    '&:hover:not(.active):not(.inbox-path)': {
      backgroundColor: 'rgba(0, 0, 0, 0.10)',
      borderRight: `3px`
    },
    '& svg:nth-child(1)': {
      viewBox: '0 0 40 40',
      height: '25px',
      width: '25px',
      '&.expand-more-icon': {
        height: '20px',
        width: '20px'
      },
      '&:not(.withStroke)': {
        fill: white
      },
      '&.withStroke': {
        fill: 'transparent',
        stroke: white
      }
    },
    '& svg:nth-child(2)': {
      position: 'absolute',
      top: 0,
      right: '-13px'
    },
    '&.inbox-path': {
      paddingLeft: '35px',
      overflow: 'hidden',
      '& .avatar': {
        display: 'flex',
        alignItems: 'center',
        width: '80%',
        '& .MuiAvatar-root': {
          width: '24px',
          height: '24px',
          fontSize: '12px',
          outline: 'none'
        },
        '& .MuiTypography-root': {
          fontSize: '14px',
          marginLeft: '16px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          color: white
        }
      }
    }
  },
  counter: {
    borderRadius: '64px',
    height: '20px',
    padding: '6.5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: NamedColors.apple[600],
    color: '#FFF',
    '& .MuiTypography-root': {
      fontSize: '12px',
      textDecoration: `none`,
      outline: 'none',
      '&:visited': {
        textDecoration: `none`,
        outline: 'none'
      }
    }
  },
  linkOutContainer: {
    '& svg:nth-child(1)': {
      '&:not(.withStroke)': {
        fill: 'none'
      }
    }
  },
  iconNameContainer: {
    display: 'flex',
    '& .MuiTypography-body1': {
      color: white,
      fontSize: '14px',
      marginLeft: '16px',
      fontWeight: 600,
      lineHeight: '20px',
      fontFamily: 'Open Sans'
    }
  },
  ellipseContainer: {
    position: 'relative'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  leasingTeamSelector: {
    padding: '16px 16px 0 16px',
    width: '100%',

    '& .MuiInputBase-root': {
      margin: '0px 0px 15px 0px',
      marginTop: '0px !important',
      maxWidth: '100%'
    },
    '& .MuiFormControl-root': {
      backgroundColor: '#FAFAFA',
      height: '55px',
      '& .MuiInputBase-root': {
        backgroundColor: '#FAFAFA'
      },
      '& label': {
        margin: '0px !important',
        left: '-3px',
        color: colors.secondaryText,
        borderRadius: '8px 8px 0px 0px'
      }
    },
    '&.focused': {
      '& .MuiFormControl-root': {
        backgroundColor: denim[50],
        '& .MuiInputBase-root': {
          backgroundColor: denim[50]
        }
      }
    }
  },
  link: {
    textDecoration: `none`,
    backgroundColor: 'none',
    outline: 'none',
    '&:hover': {
      textDecoration: `none`,
      outline: 'none'
    },
    '&:visited': {
      textDecoration: `none`,
      outline: 'none'
    },
    '&:focus': {
      outline: 'none',
      textDecoration: `none`
    }
  },
  settingsContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '14px 16px',
    cursor: 'pointer',
    borderRight: `3px solid #2EA683`,
    height: '48px',
    color: white,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    fontFamily: 'Open Sans',
    '&.active': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      borderRight: `3px solid #088D61`
    },
    '&:hover:not(.active):not(.inbox-path)': {
      backgroundColor: 'rgba(0, 0, 0, 0.10)',
      borderRight: `3px`
    }
  },
  settingsText: {
    marginLeft: '12px'
  },
  settingsIcon: {
    marginLeft: '25px'
  }
});

interface PathList {
  name: string;
  component?: React.ReactNode;
  displayComponent?: boolean;
  icon: React.ReactNode;
  key?: string;
  path?: string;
  counter?: number;
  onClick?: () => void;
  active?: boolean;
  isHide?: boolean;
  betaIcon?: React.ReactNode;
  linkOutIcon?: React.ReactNode;
}

export interface InboxProp {
  id: number;
  color: string;
  name: string;
  photo: string;
  count: number;
  initials: string;
}

export interface LeftNavigatorProps {
  goTo: (path: string, params?: any, newTab?: any) => void;
  todoCount: number;
  currentPath: string;
  inbox: InboxProp[];
  open: boolean;
  currentLeasingTeam: any;
  leasingTeams: any[];
  isMobile?: boolean;
  close?: () => void;
  hideResidents: boolean;
  hideLeasingBinder: boolean;
  leasingTeamId: string;
  openTeamInboxSettings: () => void;
  isInternalTrueUrl: boolean;
  isClawApplicantsPageAvailable?: boolean;
}
const EllipseContainer: FC<{
  children: React.ReactNode;
  displayEllipse: boolean;
}> = ({ children, displayEllipse }) => {
  const classes = useStyles();
  return (
    <Box className={classes.ellipseContainer}>
      {children}
      {displayEllipse && <EllipseIcon />}
    </Box>
  );
};

export const LeftNavigator: FC<LeftNavigatorProps> = ({
  goTo,
  todoCount,
  currentPath,
  inbox,
  leasingTeams,
  currentLeasingTeam,
  isMobile,
  close,
  hideResidents,
  hideLeasingBinder,
  leasingTeamId,
  openTeamInboxSettings,
  isInternalTrueUrl,
  isClawApplicantsPageAvailable
}) => {
  const classes = useStyles();
  const [openInbox, setOpenInbox] = useState<boolean>(false);
  const [leasingTeamsBadgeCounts, setleasingTeamsBadgeCounts] = useState();
  const [intoSearchPage, setIntoSearchPage] = useState<boolean>(false);
  const commonClasses = useCommonStyles();
  const defaultImageFileName = 'profile_placeholder.png';
  const setBackgroundColor = (
    e: React.MouseEvent<HTMLElement>,
    color: string
  ) => {
    e.currentTarget.style.backgroundColor = color;
  };

  const isDefaultIMGPresent = (photo: any) => {
    if (photo) {
      return photo.indexOf(defaultImageFileName) !== -1;
    } else {
      return false;
    }
  };

  const settingClicked = () => {
    openTeamInboxSettings();
  };

  const pathList = useMemo<PathList[]>(() => {
    const paths: PathList[] = [
      {
        name: 'Search',
        icon: <SearchPathIcon className="withStroke" />,
        active: intoSearchPage,
        onClick: () => {
          goTo('/inbox', { openSearch: true });
          setIntoSearchPage(true);
        },
        path: '/inbox'
      },
      {
        name: 'To Do List',
        key: 'todo',
        icon: (
          <EllipseContainer displayEllipse={todoCount > 0}>
            <ToDoIcon />
          </EllipseContainer>
        ),
        path: '/dashboard',
        active: currentPath === '/dashboard',
        counter: todoCount
      },
      {
        name: 'Inbox',
        component: (
          <>
            {inbox.map((inbox) => {
              let rgbColor = hexToRgb(inbox.color || '#3fa9f5')
                .split('rgb')[1]
                .split(')')[0];
              const path = '/inbox/' + inbox.id;
              const active = currentPath === path && !intoSearchPage;
              const bgColor = `rgba${rgbColor},.85`;
              const openInNewTabURL =
                path && leasingTeamId ? path + '?lt=' + leasingTeamId : path;

              return (
                <Box
                  key={`inbox-${inbox.id}`}
                  onMouseEnter={(e) =>
                    active ? false : setBackgroundColor(e, black)
                  }
                  onMouseLeave={(e) =>
                    active ? false : setBackgroundColor(e, 'transparent')
                  }
                  style={active ? { backgroundColor: black } : {}}
                >
                  <Link href={openInNewTabURL} className={classes.link}>
                    <Box
                      onClick={() => {
                        goTo(path);
                        setIntoSearchPage(false);
                      }}
                      onMouseEnter={(e) =>
                        active ? false : setBackgroundColor(e, bgColor)
                      }
                      onMouseLeave={(e) =>
                        active ? false : setBackgroundColor(e, 'transparent')
                      }
                      className={`inbox-path ${classes.pathContainer}`}
                      style={
                        active
                          ? {
                              backgroundColor: bgColor,
                              borderRight: `3px solid ${inbox.color}`,
                              paddingRight: '16px'
                            }
                          : {
                              paddingRight: '19px',
                              borderRight: '0px'
                            }
                      }
                      data-testid="inbox-item"
                    >
                      <Box className="avatar">
                        {isDefaultIMGPresent(inbox?.photo) &&
                        inbox?.name === 'Me' ? (
                          <Avatar
                            alt={`${inbox.name} inbox`}
                            style={{ backgroundColor: inbox.color }}
                          >
                            {inbox.initials}
                          </Avatar>
                        ) : (
                          <Avatar
                            alt={`${inbox.name} inbox`}
                            src={inbox.photo}
                            style={{ backgroundColor: inbox.color }}
                          >
                            {inbox.initials}
                          </Avatar>
                        )}{' '}
                        <Text>{inbox.name}</Text>
                      </Box>
                      <Box className={classes.actionsContainer}>
                        {inbox.count > 0 && (
                          <Box className={classes.counter}>
                            <Text>
                              {inbox.count > 99 ? '99+' : inbox.count}
                            </Text>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Link>
                </Box>
              );
            })}
            {isInternalTrueUrl && (
              <Box
                onClick={() => settingClicked()}
                className={classes.settingsContainer}
              >
                <span className={classes.settingsIcon}>
                  <i className="fa fa-cog"></i>
                </span>

                <span className={classes.settingsText}>Settings</span>
              </Box>
            )}
          </>
        ),
        displayComponent: openInbox,
        icon: (
          <EllipseContainer
            displayEllipse={inbox.length > 0 && inbox[0].count > 0}
          >
            <InboxIcon />
          </EllipseContainer>
        ),
        onClick: () => {
          setOpenInbox((prevState) => !prevState);
        }
      },
      {
        name: 'Prospects',
        icon: <ProspectsPathIcon />,
        path: '/reports/prospects',
        active: ['/reports/prospects', '/prospects'].indexOf(currentPath) !== -1
      },
      ...(isClawApplicantsPageAvailable
        ? [
            {
              name: 'Applicants',
              icon: <ApplicantsPathIcon />,
              betaIcon: <BetaButton />,
              linkOutIcon: (
                <ExternalLinkIcon
                  height={'16'}
                  width={'16'}
                  strokeColor="white"
                />
              ),
              onClick: () => {
                goTo(`${CLAW_BASE_URL}/applicants` || '', {}, true);
              }
            }
          ]
        : []),

      {
        name: 'Residents',
        icon: <ResidentsPathIcon />,
        path: '/reports/residents',
        active:
          ['/reports/residents', '/residents'].indexOf(currentPath) !== -1,
        onClick: () => {
          goTo('/reports/residents');
        },
        isHide: hideResidents
      },
      {
        name: 'Calendar',
        icon: <CalendarPathIcon />,
        path: '/manager/calendar',
        active: currentPath === '/manager/calendar'
      },

      {
        name: 'Availability',
        icon: <AvailabilityPathIcon />,
        path: '/units',
        active: currentPath === '/units'
      },

      {
        name: 'Leasing Binder',
        icon: <LeasingBinderPathIcon />,
        active: currentPath === '/manager/properties',
        path: '/manager/properties',
        isHide: hideLeasingBinder
      }
    ];
    return paths;
    // We don't need to check for all the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goTo, todoCount, currentPath, openInbox, inbox, intoSearchPage]);

  const { Badges } = useLeasingTeamBadges();

  useEffect(() => {
    setleasingTeamsBadgeCounts(Badges);
  }, [Badges]);

  useEffect(() => {
    let isInsideInboxActive =
      window.location.href.indexOf('/inbox/') !== -1 &&
      window.location.href.indexOf(currentPath) !== -1;

    if (isInsideInboxActive) {
      setOpenInbox(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentPath === '/inbox') {
      setIntoSearchPage(true);
    } else if (!currentPath.startsWith('/inbox')) {
      setIntoSearchPage(false);
    }
  }, [currentPath]);

  return (
    <ThemeProvider>
      <Box
        className={
          classes.navContainer +
          ' left-nav-container ' +
          commonClasses.invisibleScroll
        }
        data-testid="left-hand-navigator"
      >
        {isMobile && leasingTeams && leasingTeams.length > 1 && (
          <LeasingTeamSelector
            callback={close}
            label="Leasing Team"
            className={classes.leasingTeamSelector}
            currentLeasingTeam={currentLeasingTeam}
            leasingTeams={leasingTeams}
            leasingTeamsBadgeCounts={leasingTeamsBadgeCounts}
          />
        )}
        {pathList.map((pathItem: PathList) => {
          const {
            path,
            counter,
            onClick,
            name,
            active,
            icon,
            component,
            isHide,
            betaIcon,
            linkOutIcon
          } = pathItem;

          let openInNewTabURL =
            path && leasingTeamId ? path + '?lt=' + leasingTeamId : path;
          return (
            <Box key={name}>
              {!isHide && (
                <Link href={openInNewTabURL} className={classes.link}>
                  {name === 'Applicants' ? (
                    <Tooltip
                      title="View applications across all of your leasing teams in our new Beta."
                      placement="bottom"
                      arrow
                    >
                      <Box
                        onClick={() => {
                          if (path) {
                            goTo(path);
                          } else if (onClick) {
                            onClick();
                          }
                        }}
                        className={`${classes.pathContainer} ${
                          active ? 'active' : ''
                        }`}
                        data-testid="nav-item"
                      >
                        <Box className={classes.iconNameContainer}>
                          {icon}
                          <Text>{name}</Text>
                          {betaIcon}
                        </Box>
                        <Box className={classes.actionsContainer}>
                          {!!counter && (
                            <Box className={classes.counter}>
                              <Text>{counter}</Text>
                            </Box>
                          )}
                          {!!component && !pathItem.displayComponent && (
                            <ExpandMore className="expand-more-icon" />
                          )}
                          {!!component && pathItem.displayComponent && (
                            <ExpandLess className="expand-more-icon" />
                          )}
                          <Box className={classes.linkOutContainer}>
                            {linkOutIcon}
                          </Box>
                        </Box>
                      </Box>
                    </Tooltip>
                  ) : (
                    <Box
                      onClick={() => {
                        if (path) {
                          goTo(path);
                        } else if (onClick) {
                          onClick();
                        }
                      }}
                      className={`${classes.pathContainer} ${
                        active ? 'active' : ''
                      }`}
                      data-testid="nav-item"
                    >
                      <Box className={classes.iconNameContainer}>
                        {icon}
                        <Text>{name}</Text>
                        {betaIcon}
                      </Box>
                      <Box className={classes.actionsContainer}>
                        {!!counter && (
                          <Box className={classes.counter}>
                            <Text>{counter}</Text>
                          </Box>
                        )}
                        {!!component && !pathItem.displayComponent && (
                          <ExpandMore className="expand-more-icon" />
                        )}
                        {!!component && pathItem.displayComponent && (
                          <ExpandLess className="expand-more-icon" />
                        )}
                        <Box className={classes.linkOutContainer}>
                          {linkOutIcon}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Link>
              )}
              <Collapse in={pathItem.displayComponent}>{component}</Collapse>
            </Box>
          );
        })}
      </Box>
    </ThemeProvider>
  );
};

export const LeftNavigatorDrawer: FC<LeftNavigatorProps> = (props) => {
  const isMobile = useMediaQuery('(max-width:428px)');
  const classes = useStyles();

  const sideNav = useMemo(
    () => (
      <Provider store={store}>
        <LeftNavigator {...props} isMobile={isMobile} />
      </Provider>
    ),
    [isMobile, props]
  );

  if (isMobile) {
    return (
      <Drawer
        anchor="left"
        open={props.open}
        className={classes.drawerContainer}
        ModalProps={{
          style: {
            zIndex: 1200
          },
          container: () => document.getElementById('angular-viewport')
        }}
      >
        {sideNav}
      </Drawer>
    );
  }
  return sideNav;
};
