import { getAuthToken } from './tokenService';

const API_HOST =
  process.env.REACT_APP_DOCKER_API_HOST || process.env.REACT_APP_API_HOST;

export interface NearbyCommunitiesSearchPreferences {
  radius?: number;
  unit?: 'km' | 'mi';
  bedrooms?: string[];
  minPrice?: number;
  maxPrice?: number;
  allowCats?: boolean;
  allowSmallDogs?: boolean;
  allowLargeDogs?: boolean;
}

function getScopedLeasingTeamIdForTS() {
  let queryParams = new URLSearchParams(window.location.search);
  let scopedLeasingTeamId = queryParams.get('lt');

  if (!scopedLeasingTeamId && !!window.location.hash) {
    let hashParams = new URLSearchParams(
      window.location.hash.replace('#', '?')
    );
    scopedLeasingTeamId = hashParams.get('lt');
  }

  return scopedLeasingTeamId ? scopedLeasingTeamId : '';
}
/**
 * Converts a NearbyCommunitiesSearchPreferences to query params in the way the API expects it
 * (URLSearchParams, snake case)
 */
export const nearbyCommunitiesSearchPreferencesToQueryParams = (
  data: NearbyCommunitiesSearchPreferences
): URLSearchParams => {
  const params = new URLSearchParams();

  if (data.radius) {
    params.append('radius', String(data.radius));
  }

  if (data.unit) {
    params.append('unit', data.unit);
  }

  if (data.bedrooms && data.bedrooms.length) {
    data.bedrooms.map((bedrooms) =>
      params.append('bedrooms', String(bedrooms))
    );
  }

  if (data.minPrice) {
    params.append('min_price', String(data.minPrice));
  }

  if (data.maxPrice) {
    params.append('max_price', String(data.maxPrice));
  }

  if (data.allowCats) {
    params.append('allow_cats', String(data.allowCats));
  }

  if (data.allowLargeDogs) {
    params.append('allow_large_dogs', String(data.allowLargeDogs));
  }

  if (data.allowSmallDogs) {
    params.append('allow_small_dogs', String(data.allowSmallDogs));
  }

  return params;
};

export enum CAN_NOT_REFER_REASON {
  PROSPECT_EXISTS = 'prospect_exists',
  RESIDENT_EXISTS = 'resident_exists',
  NO_PHONE_NO_EMAIL = 'no_phone_no_email'
}

export type CanNotReferReason =
  | 'prospect_exists'
  | 'resident_exists'
  | 'no_phone_no_email';

// Pulled from Prospect.Status in APIv2
export type ProspectStatus =
  | 'new'
  | 'open'
  | 'waiting'
  | 'booked'
  | 'visited'
  | 'cancelled-manager'
  | 'cancelled-renter'
  | 'no-show'
  | 'in-review'
  | 'applied-lost'
  | 'applied-leased'
  | 'applied-pending-signature'
  | 'application-in-progress'
  | 'lost';

// Pulled from Resident.Status in APIv2
export type ResidentStatus =
  | 'current'
  | 'former'
  | 'on-notice'
  | 'renewing'
  | 'transfer';

export interface NearbyCommunity {
  property: {
    id: number;
    name: string;
    // decimal
    distance: number;
    unit: string;
    // undefined if unable to get units count for this property
    available_units_count?: number | null;
    url?: string;
    can_not_refer: boolean;
    can_not_refer_reason?: CanNotReferReason;
  };
  prospect?: {
    id: number;
    status: ProspectStatus;
    status_label: string;
  };
  resident?: {
    id: number;
    status: ResidentStatus;
    status_label: string;
  };
}

/**
 * Fetches nearby communities from a propertyId, prospectId,
 * and given custom preferences.
 */
const getNearbyCommunities = async (
  propertyId: number,
  prospectId: number,
  customPreferences?: NearbyCommunitiesSearchPreferences
): Promise<NearbyCommunity[]> => {
  try {
    if (!customPreferences) {
      customPreferences = {};
    }
    const queryParams =
      nearbyCommunitiesSearchPreferencesToQueryParams(customPreferences);
    const resp = await fetch(
      `${API_HOST}/property/${propertyId}/referrals/${prospectId}?${queryParams.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`
        }
      }
    );
    const data = await resp.json();

    if (resp.status === 400) {
      throw new Error(data.message || 'Invalid request');
    }

    if (resp.status === 404) {
      throw new Error(data.message || 'Not found');
    }

    if (resp.status === 401) {
      throw new Error(data.message || 'Not authorized');
    }

    if (resp.status === 403) {
      throw new Error(data.message || 'Not authenticated');
    }

    if (resp.status === 500) {
      throw new Error(data.message || 'Unexpected error');
    }

    // May return undefined, but the key should exist.
    if ('properties' in data) {
      let properties: NearbyCommunity[] = data.properties || [];

      // sort properties in ascending order by distance
      return properties.sort(
        (a, b) => a.property.distance - b.property.distance
      );
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (e) {
    console.error('Nearby Communities error:', e);
    throw new Error(`Unable to fetch nearby communities: ${e}`);
  }
};

export interface ReferProspectPayload {
  note: string;
  // toISOString (not tz aware)
  followUpDate?: string;
  isScheduling: boolean;
}

export interface ReferProspectResponseData {
  sister_property_prospect_id: number;
  sister_property_prospect_renter_id: number;
  sister_property_prospect_knock_id: string;
}

/**
 * Struct that bridges the responses from the server to the ReferralError enum.
 * It's a layer of separation.
 */
export enum ReferralErrorResponses {
  PERSON_ALREADY_EXISTS = 'person_already_exists',
  INVALID_PROSPECT_REFERRAL = 'invalid_prospect_referral',
  BAD_TRANSACTION = 'bad_transaction'
}
export enum ReferralError {
  INVALID_PROSPECT = 1,
  ALREADY_EXISTS = 2,
  BAD_TRANSACTION = 3
}

export type ReferProspectResponse =
  | { ok: true; response: ReferProspectResponseData }
  | { ok: false; error: ReferralError };

const referProspect = async (
  sourceProspectId: number,
  sisterPropertyId: number,
  body: ReferProspectPayload
): Promise<ReferProspectResponse> => {
  try {
    const resp = await fetch(
      `${API_HOST}/prospect/${sourceProspectId}/refer/${sisterPropertyId}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
          'Content-Type': 'application/json',
          'x-knock-auth-as-leasing-team': getScopedLeasingTeamIdForTS()
        },
        body: JSON.stringify({
          note: body.note,
          is_scheduling: body.isScheduling,
          follow_up_date: body.followUpDate
        })
      }
    );

    const data: any = await resp.json();

    if (resp.ok) {
      return {
        ok: true,
        response: data as ReferProspectResponseData
      };
    } else {
      const message = data.message as ReferralErrorResponses;

      if (!message) {
        throw Error('Bad failure response');
      }

      switch (message) {
        case ReferralErrorResponses.PERSON_ALREADY_EXISTS:
          return {
            ok: false,
            error: ReferralError.ALREADY_EXISTS
          };
        case ReferralErrorResponses.INVALID_PROSPECT_REFERRAL:
          return {
            ok: false,
            error: ReferralError.INVALID_PROSPECT
          };
        case ReferralErrorResponses.BAD_TRANSACTION:
          return {
            ok: false,
            error: ReferralError.BAD_TRANSACTION
          };
      } // no default needed. Typescript ensures all members of the enum are satisfied.
    }
  } catch (e) {
    console.error('referProspect error:', e);
    throw new Error(`Unable to refer prospect: ${e}`);
  }
};

export const multipleRefersForProspect = async (
  prospectId: number,
  propertyIds: number[],
  body: ReferProspectPayload
) => {
  const reqs: Promise<ReferProspectResponse>[] = [];

  for (let i = 0; i < propertyIds.length; i++) {
    const id = propertyIds[i];
    reqs.push(referProspect(prospectId, id, body));
  }
  return Promise.all(reqs);
};

const nearbyCommunitiesService = {
  referProspect,
  getNearbyCommunities
};

export type NearbyCommunitiesService = typeof nearbyCommunitiesService;

export default nearbyCommunitiesService;
