import React, { FC } from 'react';

export interface ExternalLinkIconProps {
  height?: string;
  width?: string;
  className?: string;
  strokeColor?: string;
}

export const ExternalLinkIcon: FC<ExternalLinkIconProps> = ({
  height,
  width,
  className,
  strokeColor = '#353950'
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 9V3H15M20 4L12 12M10.3333 7H2.33333C1.59695 7 1 7.59695 1 8.33333V21.6667C1 22.403 1.59695 23 2.33333 23H15.6667C16.403 23 17 22.403 17 21.6667V13.6667"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
